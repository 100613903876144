import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeading"
import ProductCard from "../components/ProductCard"
import styled from "styled-components"

import L28DImage from "../images/l-series/timberline-l-series-28-disk-19g-19g-tanks.jpg"
import L24OImage from "../images/l-series/timberline-l-series-28-orbital-19g-19g-tanks.jpg"
import L28OImage from "../images/l-series/timberline-l-series-28-orbital-19g-19g-tanks.jpg"
import L26CImage from "../images/l-series/timberline-l-series-26-cylind-19g-19g-tanks.jpg"

import IndexSection3 from "../components/IndexSection3"

import SEO from "../components/seo"
import Helmet from "react-helmet"

const LpageStyles = styled.div`
  #product-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-items: center;

    @media (max-width: 960px) {
      grid-template-columns: 50% 50%;
    }

    @media (max-width: 650px) {
      grid-template-columns: 100%;
    }
  }
`

const LseriesPage = () => {
  return (
    <>
      <SEO title="Large Walk Behind Floor Scrubbers | Timberline Cleaning Equipment" />
      <Helmet>
        <title>
          Large Walk Behind Floor Scrubbers | Timberline Cleaning Equipment
        </title>
        <description>
          L-Series Walk-Behind Scrubbers are built for substatial productivity
          with large scrub heads and large clean solution tanks.
        </description>
      </Helmet>
      <MainLayout>
        <PageHeading
          title="Large Walk Behind Floor Scrubbers"
          subHeading="L-Series"
          description="L-Series Walk-Behind Scrubbers are built for substantial productivity with large scrub heads and large clean solution tanks.."
          path="24 - 28in (61 - 71cm)"
          tank="Sol: 19gal (72L) Rec: 19gal (72L)"
          time="Up to 3.5 hours"
          title1="Cleaning Path:"
          title2="Tank Capacity:"
          title3="Run Time:"
        />
        <LpageStyles>
          <div id="product-container">
            <ProductCard
              image={L28DImage}
              title="L26 Disk"
              description="Cleaning Path - 26 in / 66 cm"
              link="/products/l26-disk-scrubber"
            />
            <ProductCard
              image={L24OImage}
              title="L24 Orbital "
              description="Cleaning Path - 24 in / 61 cm"
              link="/products/l24-orbital-scrubber"
            />
            <ProductCard
              image={L26CImage}
              title="L26 Cylindrical"
              description="Cleaning Path - 26 in / 66 cm"
              link="/products/l26-cylindrical-scrubber"
            />

            <ProductCard
              image={L28DImage}
              title="L28 Disk"
              description="Cleaning Path - 28 in / 71 cm"
              link="/products/l28-disk-scrubber"
            />
            <ProductCard
              image={L28OImage}
              title="L28 Orbital"
              description="Cleaning Path - 28 in / 71 cm"
              link="/products/l28-orbital-scrubber"
            />
            {/* <ProductCard
              image={L26CImage}
              title="L26 Cylindrical"
              description="Cleaning Path - 26 in / 660 mm"
              link="/products/l26-cylindrical-scrubber"
            /> */}
            {/* <ProductCard
              image={L30CImage}
              title="L30 Cylindrical"
              description="Cleaning Path - 30 in / 762 mm"
              link="/products/l30-cylindrical-scrubber"
            /> */}
          </div>
        </LpageStyles>
        <IndexSection3 title="Request a Free Demo at your Facility" />
      </MainLayout>
    </>
  )
}

export default LseriesPage
